import React, { useEffect }   from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import firebase from "firebase";
import CircularProgress from '@material-ui/core/CircularProgress';
import DropzoneProfile from '../../../modules/components/DropzoneProfile'
import { connect } from 'react-redux'
import { selectCurrentUser } from '../../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import PhoneInput from 'react-phone-input-2'
import Dialog from '../../../modules/components/Dialog';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AddAddress from "../Checkout/AddAddress"
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  }
}));

function Index (props){
  

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [profileBio, setProfileBio] = React.useState("");
  const [profileEmail, setProfileEmail] = React.useState("");

  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");
  const [mediaOption, setMediaOption] = React.useState("address_billing");
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [address, setAddress] = React.useState(null);

  useEffect(() => {
    if(props.currentUser){
      setFirstName(props.currentUser.first_name ? props.currentUser.first_name : "")
      setLastName(props.currentUser.last_name ? props.currentUser.last_name : "")
      setProfileBio(props.currentUser.bio ? props.currentUser.bio : "")
      setProfileEmail(props.currentUser.email ? props.currentUser.email : "")
      setLoading(false)
    }
  }, [props]);

  const handleNameChange = (e) => {
    setFirstName(e.target.value)
  }

  const handleLastNameChange = (e) => {
    setLastName(e.target.value)
  }

  const handleEmailChange = (e) => {
    setProfileEmail(e.target.value)
  }

  const handleBioChange = (e) => {
    setProfileBio(e.target.value)
  }

  const saveProfile = () => {
    setMediaOption("save_profile")
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Save Profile")
    setOptions("saveProfile")
    setDialogDes("Do you want save the changes ?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const saveAddressHandle = (address) => {

    var updates = {};

    if(props.currentUser.address){

      let totalAddress = [];

      props.currentUser.address.forEach((val, index)=>{
        if(address.type === val.type){
          totalAddress.push(val)
        }
      })

      if(totalAddress.length === 0){
        address.selected = true;
      }
      props.currentUser.address.push(address)

      if(address.selected){
        props.currentUser.address.forEach((val, index)=>{
          if(address.type === val.type && val !== address){
            val.selected = false;
          }
        })
      }

      updates['agent/' + props.currentUser.id] = props.currentUser;
      
      firebase.database().ref().update(updates);
    }
    else{
      address.selected = true;
      props.currentUser.address = [address]

      updates['agent/' + props.currentUser.id] = props.currentUser;
      
      firebase.database().ref().update(updates);
    }

    setOpenDialog(false)
  }

  const saveEditAddressHandle = (oldAddress, newAddress) => {
    var currentIndex = props.currentUser.address.map(val=> { return val}).indexOf(oldAddress);

    if(currentIndex !== -1){
      props.currentUser.address[currentIndex] = newAddress;
    }

    if(props.currentUser.address[currentIndex].selected){
      props.currentUser.address.forEach((val, index)=>{
        if(newAddress.type === val.type && val !== newAddress){
          val.selected = false;
        }
      })
    }

    var updates = {};
    updates['agent/' + props.currentUser.id] = props.currentUser;
      
    firebase.database().ref().update(updates);

    setOpenDialog(false)
  }

  const addAddress = (type) => {
    setMediaOption("address_" + type)
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Add Address")
    setOptions("addItem")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
    setAddress(null)
  }

  const editAddress = (type, val) => {
    setMediaOption("address_" + type)
    setDialogLoading(false)
    setAgreeButton(false)
    setDialogTitle("Edit Address")
    setOptions("editItem")
    setDialogDes("")
    setFullScreenDialog(false)
    setOpenDialog(true)
    setAddress(val)
  }

  const removeAddress = (val) => {
    setSelectedAddress(val)
    setMediaOption("remove_address")
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Remove Address")
    setOptions("removeItem")
    setDialogDes("Are you sure want to remove this address?")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const selectAddress = (type, value) => {

    props.currentUser.address.forEach((val)=>{
      if(val.type === type){
        if(val === value){
          val["selected"] = true;
        }
        else
        {
          val["selected"] = false;
        }
      }
      
    })

  }


  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleAgree = () => {

    var updates = {};

    if(mediaOption === "remove_address"){
      
      let currentAddress = props.currentUser.address.indexOf(selectedAddress);

      if (currentAddress > -1) {
        props.currentUser.address.splice(currentAddress, 1);
      }

      updates['agent/' + props.currentUser.id] = props.currentUser;
      
      firebase.database().ref().update(updates);

    }

    else if(mediaOption === "save_profile"){
      props.currentUser.first_name = firstName;
      props.currentUser.last_name = lastName;
      props.currentUser.email = profileEmail;
      props.currentUser.bio = profileBio;

      updates['agent/' + props.currentUser.id] = props.currentUser;
      
      firebase.database().ref().update(updates);
    }
   
    setOpenDialog(false)
  }

  const renderAddressLayout = (type) => {
    return(
      <Grid style={{ backgroundColor: "#424242", padding: 30, marginTop: 20, borderRadius: 5}}>

          <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
            <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Grid style={{ backgroundColor: "#99d700", borderRadius: 50, width: 30, height: 30, 
              display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography style={{ color: "black"}}>
                  3
                </Typography>
              </Grid>
              {type === "shipping" ? <Typography style={{ marginLeft: 10 }}>
                Shipping Address
              </Typography> : <Typography style={{ marginLeft: 10 }}>
                Billing Address
              </Typography>}
            </Grid>
            <Grid>
              <IconButton size="small" onClick={()=>addAddress(type)}>
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 20 }}>
            {props.currentUser.address ? 
            props.currentUser.address.map((val, index)=> {

              let colorBorder = "1px solid " + (val.selected ? "#99d700": "#7e7e7e");

              return(
                <Grid item md={4} xs={6} key={type + "-" + index} style={{ display: val.type === type ? "flex": "none"}}>
                    
                    <Grid style={{ border:colorBorder, borderRadius: 5, minHeight: 120, width: "100%" }}>
                      <Grid style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: 10 }}>
                        <IconButton style={{ marginRight: 10 }} size="small" onClick={()=>editAddress(type, val)} >
                          <EditIcon  style={{ fontSize: 12 }} />
                        </IconButton>
                        <IconButton size="small" onClick={()=> removeAddress(val)}>
                          <CloseIcon  style={{ fontSize: 12 }} />
                        </IconButton>
                      </Grid>
                      <ListItem onClick={()=> selectAddress(type, val)} button style={{ padding: 15 }}>
                        <Grid>
                          <Typography variant={"body2"}>
                          {val.title}
                          </Typography>
                          <Grid style={{ marginTop: 10, opacity:.7, overflow: "hidden", textOverflow: "ellipsis" }}>
                            <Typography variant={"caption"} >
                            {val.street_address + ", " + val.city + ", " + val.zip + " " + val.state + ", " + val.country}
                          </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                      
                    </Grid>

                </Grid>
              )
              
            })
              : null}
          </Grid>
        </Grid>
    )
  }

  const renderComponent = () => {
    if(mediaOption === "address_billing"){
      return <AddAddress address={address} type={"billing"} 
      saveEditAddressHandle={(oldAddress, newAddress) => saveEditAddressHandle(oldAddress, newAddress)} 
      saveAddressHandle={(address)=> saveAddressHandle(address)}/>
    }
    else if(mediaOption === "address_shipping"){
      return <AddAddress address={address} type={"shipping"} 
      saveEditAddressHandle={(oldAddress, newAddress) => saveEditAddressHandle(oldAddress, newAddress)} 
      saveAddressHandle={(address)=> saveAddressHandle(address)}/>
    }
  }
  
  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }

  return (
    <Grid className={classes.root}>
      <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          handleAgree={handleAgree}
          component={renderComponent()}
          handleClose={handleClose}/>
      <Grid container >
        <Grid item xs={12} md={12} style={{ padding: 20 }} >
          <Grid style={{ display: "flex", flexDirection: "column", padding: 20, backgroundColor: "#363636", border: "1px solid #7e7e7e", borderRadius: 5 }}>
            <Grid container>
              <Grid item md={3} xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Avatar style={{ width: 150, height: 150 }} src={props.currentUser.image ? props.currentUser.image.url : ""} />
              </Grid>
              <Grid item md={9} xs={12}>
                <DropzoneProfile {...props}/>
              </Grid>
            </Grid>
            <Grid container style={{ width: "100%", marginTop: 20 }}>
              <Grid item xs={6}>
              <TextField
                  label={"First Name"}
                  style={{ width: "100%" }}
                  onChange={handleNameChange}
                  value={firstName}
                  variant="outlined"/>
              </Grid>
              <Grid item xs={6} style={{ width: "100%", paddingLeft: 20  }}>
              <TextField
                  label={"Last Name"}
                  style={{ width: "100%" }}
                  onChange={handleLastNameChange}
                  value={lastName}
                  variant="outlined"/>
              </Grid>

            </Grid>
            <Grid>
              <TextField
                  label={"Email"}
                  style={{ width: "100%", marginTop: 20 }}
                  onChange={handleEmailChange}
                  value={profileEmail}
                  variant="outlined"/>
            </Grid>
            <Grid>
              <TextField
                  multiline
                  label={"Bio"}
                  style={{ width: "100%", marginTop: 20 }}
                  onChange={handleBioChange}
                  value={profileBio}
                  variant="outlined"/>
            </Grid>
            <Grid style={{ display: "flex", justifyContent: "flex-end"}}>
              <Button onClick={()=> saveProfile()} style={{ marginTop: 20 }} color="primary" disableElevation variant="contained">
                Save
              </Button>
            </Grid>

            
          </Grid>

          <Grid style={{ marginTop: 20, display: "flex", flexDirection: "column", padding: 20, backgroundColor: "#363636", border: "1px solid #7e7e7e", borderRadius: 5 }}>
            <Typography variant="h6" noWrap={true}>{"Contact Number"}</Typography>
            <PhoneInput
                containerStyle={{ marginTop: 10, marginBottom: 10 }}
                dropdownStyle={{ color: "#1c1c1c" }}
                inputStyle={{ width: "100%"}}
                country={'my'}
                value={props.currentUser.phone}
                disabled
              />
          </Grid>

          <Grid style={{ marginTop: 20, display: "flex", flexDirection: "column", padding: 20, backgroundColor: "#363636", border: "1px solid #7e7e7e", borderRadius: 5 }}>
            <Typography variant="h6" noWrap={true}>{"Address"}</Typography>
            {renderAddressLayout("billing")}
            {renderAddressLayout("shipping")}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    
  );
  
}

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index);