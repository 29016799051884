import React, { useEffect } from 'react';
import TopBanner from './Main/TopBanner';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '../../modules/components/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import firebase from "firebase";
import { selectCurrentUser } from '../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));
  
function Index(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [errorSubject, setErrorSubject] = React.useState(false);
  const [errorDes, setErrorDes] = React.useState(false);
  const [userFill, setUserFill] = React.useState({
    subject: "",
    description: ""
  });

  //snackbar
  const [openBar, setOpenBar] = React.useState(false);

  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");

  useEffect(() => {
    setLoading(false)
  }, []);


  const handleCloseSnackBar = () => {
    setOpenBar(false)
  };

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleAgree = () => {
    if(option === "send_message"){
      userFill.user_phone = props.currentUser.phone;
      userFill.user_uid = props.currentUser.id;
      userFill.created = firebase.database.ServerValue.TIMESTAMP;
      
      firebase.database().ref('request').push(userFill)
    }

    setUserFill({
      subject: "",
      description: ""
    })
    setOpenDialog(false)
    setOpenBar(true)
  }

  const handleUserFillChange = (prop) => (event) => {
    setUserFill({ ...userFill, [prop]: event.target.value });
  };

  const handleSubmit = () => {

    let passAll = true;

    if(userFill.subject.length < 5){
      passAll = false;
      setErrorSubject(true)
    }
    else
    {
      setErrorSubject(false)
    }

    if(userFill.description.length < 15){
      passAll = false;
      setErrorDes(true)
    }
    else
    {
      setErrorDes(false)
    }

    if(passAll){
      setDialogLoading(false)
      setAgreeButton(true)
      setDialogTitle("Confirm send")
      setOptions("send_message")
      setDialogDes("Are you sure want to send this message? ")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }
    

   
  }

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }

  return (
    <Grid className={classes.root}>
      <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          handleAgree={handleAgree}
          handleClose={handleClose}/>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openBar}
        onClose={handleCloseSnackBar}
        message={<Grid>
          <Typography variant='body2' style={{ opacity: .7 }}>{"Thank you for contacting us. "}</Typography>
          <Typography variant='body2' style={{ opacity: .7 }}>{"We'll get back to you soon."}</Typography>
          </Grid>}
        autoHideDuration={6000}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackBar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <TopBanner/>
      <Grid style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Grid style={{ maxWidth: 1000, width: "100%" }}>
          <Grid container spacing={2} style={{ width: "100%", padding: 20 }} >
              <Grid item xs={12} md={4}>
                <Grid style={{ backgroundColor: "#424242", height: 500, padding: 30 }}>
                  <Grid style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <img style={{ maxWidth: 300 }} width="100%" src="assets/contact/contact-illustration.svg" alt="contact-bg" />
                  </Grid>
                  <Typography variant='body2' style={{ fontWeight: "bold", marginTop: 20 }}>
                   Address
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 10, opacity: .7 }}>
                  15, Jalan Radin Bagus 7, Bandar Baru Sri Petaling, 57000 Kuala Lumpur.
                  </Typography>

                  <Typography variant='body2' style={{ fontWeight: "bold", marginTop: 10 }}>
                   Phone
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 10, opacity: .7 }}>
                  016-529 5929
                  </Typography>

                  <Typography variant='body2' style={{ fontWeight: "bold", marginTop: 10 }}>
                   Email
                  </Typography>
                  <Typography variant='body2' style={{ marginTop: 10, opacity: .7 }}>
                  info@sp2smalaysia.com
                  </Typography>

                </Grid>
              </Grid>
              <Grid item xs={12} md={8} >
                <Grid style={{ backgroundColor: "#424242",  padding: 30 }}>
                <Typography variant='h6'>
                Request service
                </Typography>
                <Typography variant='body2' style={{ marginTop: 20 }}>
                  Subject
                </Typography>
                <TextField
                  error={errorSubject}
                  helperText={errorSubject ? "What subject do you want to discuss with us?" : ""}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete='off'
                  id="subject"
                  name="subject"
                  size="small"
                  style={{ marginTop: 5}}
                  value={userFill["subject"]}
                  onChange={handleUserFillChange("subject")}
                />

                <Typography variant='body2' style={{ marginTop: 10}}>
                Description
                </Typography>
                <TextField
                  error={errorDes}
                  helperText={errorDes ? "Tell us more about it" : ""}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  minRows={3}
                  autoComplete='off'
                  id="description"
                  name="description"
                  size="small"
                  style={{ marginTop: 5}}
                  value={userFill["description"]}
                  onChange={handleUserFillChange("description")}
                />
              
                <Button
                  style={{textTransform: 'none', marginTop: 20}}
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
                >
                Submit
                </Button>

                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
      
      
    </Grid>
  );
}

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index);