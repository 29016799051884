import RedeemActionTypes from './redeem.types'
import { addItemToRedeem, removeItemFromRedeem, changeItemQuatityToRedeem } from './redeem.utils'
import firebase from "firebase";

const INITIAL_STATE = {
    hidden: true,
    redeemItems: []
}


const redeemReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case RedeemActionTypes.TOGGLE_REDEEM_HIDDEN:
            return {
                ...state,
                hidden: !state.hidden
            }
        case RedeemActionTypes.ADD_REDEEM_ITEM:

            var _addItemToCard = addItemToRedeem(state.redeemItems, action.payload);
            
            firebase.database().ref("redeems/"+ action.user.id).update({ 
                updated: firebase.database.ServerValue.TIMESTAMP, items: _addItemToCard });
            
            return {
                ...state,
                redeemItems: _addItemToCard
            }

        case RedeemActionTypes.CHANGE_REDEEM_ITEM_QUANTITY:
            
            var _changeItemQuantityToCard = changeItemQuatityToRedeem(state.redeemItems, action.payload, action.quatity);
            
            firebase.database().ref("redeems/"+ action.user.id).update({ 
                updated: firebase.database.ServerValue.TIMESTAMP, items: _changeItemQuantityToCard });
            
            return {
                ...state,
                redeemItems: _changeItemQuantityToCard
            }

        case RedeemActionTypes.CLEAR_REDEEM_ITEM_FROM_REDEEM:

            var _clearItemToCard = state.redeemItems.filter(redeemItem => redeemItem.id !== action.payload.id);

            firebase.database().ref("redeems/"+ action.user.id).update({ 
                updated: firebase.database.ServerValue.TIMESTAMP, items: _clearItemToCard });

            return {
                ...state,
                redeemItems: _clearItemToCard
            }

        case RedeemActionTypes.REMOVE_REDEEM_ITEM:

            var _removeItemToCard = removeItemFromRedeem(state.redeemItems, action.payload);

            firebase.database().ref("redeems/"+ action.user.id).update({ 
                updated: firebase.database.ServerValue.TIMESTAMP, items: _removeItemToCard });

            return {
                ...state,
                redeemItems: _removeItemToCard
            }
        case RedeemActionTypes.SET_REDEEM_ITEM:

            firebase.database().ref("redeems/"+ action.user.id).update({ 
                updated: firebase.database.ServerValue.TIMESTAMP, items: action.payload });

            return {
                ...state,
                redeemItems: action.payload
            }
        default:
            return state
    }
}

export default redeemReducer