import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import CircularProgress from '@material-ui/core/CircularProgress'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Link from '@material-ui/core/Link';
import firebase from 'firebase';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sp2smalaysia.com/">
      Sp2s Malaysia
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const styles = theme => ({
  root: {
    display:"flex",
    justifyContent: "center",
    padding: 20
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
});

function SignIn(props) {

  const classes = props.classes;
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const [isPhoneNumber, setIsPhoneNumber] = React.useState(true);
  const [isOTPSent, setIsOTPSent] = React.useState(false);
  const [isOTPCorrect, setIsOTPCorrect] = React.useState(true);
  const [openBar, setOpenBar] = React.useState(false);
  const [message, setMessage] = React.useState("");


  const recaptchaRef = React.useRef();
  

  useEffect(() => {

    setLoading(false)

  }, []);

  const handleBarClose = (event, reason) => {
    setOpenBar(false);
  };

  const handleSignIn = (event) => {

    event.preventDefault();

    if(isOTPSent){

      
      if(otp !== "" && otp.length === 6){

        setLoading(true)
        setIsOTPCorrect(true)
        let optConfirm = window.confirmationResult;
        optConfirm
          .confirm(otp)
          .then(function (result) {
            // User signed in successfully.
            // console.log("Result" + result.verificationID);
            //setLoading(false)
          })
          .catch(function (error) {
            console.log(error);
            if(error.code === "auth/invalid-verification-code" || error.code === "auth/missing-verification-code"){
              
            }

            setIsOTPCorrect(false)
              setLoading(false)
          });
      }
      else
      {
        setIsOTPCorrect(false)
      }
      
      
    }
    else
    {
      sendOTPHandle();
    }
    
  }

  const sendOTPHandle = () => {

    let phone_number_result = true;

    if (phoneNumber === "" || phoneNumber.length < 10)
    {
      phone_number_result = false;
    }

    if(phone_number_result){
      setIsPhoneNumber(true)
    }
    else{
      setIsPhoneNumber(false)
    }

    if(phone_number_result === true){
      
      setLoading(true)

      setUpRecaptcha();
      let appVerifier = window.recaptchaVerifier;
        firebase
          .auth()
          .signInWithPhoneNumber("+"+phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            // console.log(confirmationResult);
            setLoading(false)
            setIsOTPSent(true)

          })
          .catch(function (error) {
            console.log(error);
            setMessage(error.message)
            setOpenBar(true)
            window.recaptchaVerifier.clear()
      recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`
            setLoading(false)
          });

    }
  }

  const setUpRecaptcha = () => {

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          handleSignIn();
        },
      }
    );

  };

  const otphandleChange = (event) => {
    setOtp(event)
  }

  const resendOtpHandle = () => {
    setOtp("");
    setIsOTPSent(false)
    setIsOTPCorrect(true)

    if (window.recaptchaVerifier && recaptchaRef) {
      window.recaptchaVerifier.clear()
      recaptchaRef.current.innerHTML = `<div id="recaptcha-container"></div>`
    }
    
  }

  const onEnterPress = (e) => {
    if(e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSignIn(e)
    }
  }

  return (
    <Grid container component="main" className={classes.root}>
      <div ref={recaptchaRef} >
        <div id="recaptcha-container"></div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openBar}
        autoHideDuration={6000}
        onClose={handleBarClose}
        message={message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleBarClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      
      <Grid item component={Paper} elevation={0} style={{ width: 400, marginTop: 50, backgroundColor: "#1c1c1c" }}>
        <Grid style={{ display: 'flex', justifyContent: "space-between", height: 150, backgroundColor: "#212121", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
            <Grid >
              <Typography color="primary" variant='body1' style={{  marginLeft: 30, marginTop: 20, fontWeight: "bold"}}>
                Sp2s Malaysia Agent
              </Typography>
              <Typography color="primary" variant='body2' style={{  marginLeft: 30 }}>
                Sign in to continue
              </Typography>
            </Grid>
            
            <Grid style={{ height: "100%", display: "flex", alignItems: "flex-end"}}>
            <img style={{ maxHeight: 100 }} height="100%" src="assets/login/profile-img.png" alt="top-login-bg" />
            </Grid>
        </Grid>
        <Grid style={{ display: 'flex', justifyContent: "center", alignItems: 'center', position: "absolute", backgroundColor: "#3d3d3d", width: 80, height: 80, marginLeft: 30, marginTop: -40, borderRadius: 50 }}>
          <LockIcon fontSize="large"/>
        </Grid>
        <div className={classes.paper}>
          
          {loading ? 
          
          <div style={{ display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 70, marginBottom: 70  }}>
              <CircularProgress 
                disableShrink
                color="primary" />
          </div>
          
          : 
          
          <form className={classes.form} onSubmit={handleSignIn}>
               
              <Typography variant='body2' style={{ marginTop: 20}}>
                Phone Number
              </Typography>
              <PhoneInput
                containerStyle={{ marginTop: 10, marginBottom: 10 }}
                dropdownStyle={{ color: "#1c1c1c" }}
                inputStyle={{ width: "100%"}}
                disabled={isOTPSent}
                country={'my'}
                value={phoneNumber}
                onChange={setPhoneNumber}
                onKeyDown={onEnterPress}
              />
              {!isPhoneNumber ? 
              <Typography variant='caption' style={{ color: "red" }}>
                Please enter valid phone number
              </Typography> : null}

              {isOTPSent ? <Grid style={{ display:"flex", justifyContent: "flex-end", width: "100%"}}>
              <Link color="inherit" onClick={()=> resendOtpHandle()}><Typography variant='caption'>
                Resend OTP
              </Typography>
              </Link>
              </Grid> : null }

              {isOTPSent ? <Typography variant='body2' style={{ marginTop: 20}}>
                Enter OTP
              </Typography> : null }
              {isOTPSent ? <OtpInput
                containerStyle={{ width: "100%"}}
                inputStyle={{ height: 50, width: 49, marginTop: 10, marginBottom: 10, borderRadius: 5, fontSize: 30 }}
                value={otp}
                onChange={otphandleChange}
                numInputs={6}
                isInputNum
                separator={<span>-</span>}
              /> : null }

              {!isOTPCorrect ? 
              <Typography variant='caption' style={{ color: "red" }}>
                The OTP you've entered is incorrect. Please try again.
              </Typography> : null}

            
              <Button
                style={{textTransform: 'none'}}
                fullWidth
                color="primary"
                variant="contained"
                className={classes.submit}
                disableElevation
                type="submit"
                
              >
              {isOTPSent ? 'Log In' : 'Send OTP'}
              </Button>

              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
              <img height="80" src="assets/logo/logo-white.png" alt="Sp2s logo"/>
              </Grid>
              
              
              <Grid style={{ display: 'flex', justifyContent: "center", marginTop: 20 }}>
                <Copyright />
              </Grid>
          </form>}
        </div>
      </Grid>
    </Grid>);
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);