import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import PhoneInput from 'react-phone-input-2';
import Dialog from '../../../modules/components/Dialog';
import TextField from '@material-ui/core/TextField';
import firebase from "firebase";
import ListItem from '@material-ui/core/ListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom"
import AddAddress from "./AddAddress"
import moment from "moment";

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCartItems, selectCartTotal } from '../../../redux/cart/cart.selectors'
import { selectCurrentUser } from '../../../redux/user/user.selector'
import { selectCurrentSalesman } from '../../../redux/salesman/salesman.selector'
import { selectProductItems } from '../../../redux/product/product.selectors';
import {
  setCurrentUser
} from '../../../redux/user/user.actions';
import {
  setItem
} from '../../../redux/cart/cart.actions';


const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  }
}));

function Index(props) {

    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [currentCoupon, setCurrentCoupon] = React.useState(null);
    let history = useHistory();
    const coupon_code =  React.useRef("");
    

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");
    const [mediaOption, setMediaOption] = React.useState("address_billing");
    const [selectedAddress, setSelectedAddress] = React.useState(null);
    const [address, setAddress] = React.useState(null);
    const [errorShipping, setErrorShipping] = React.useState(false);
    const [errorBilling, setErrorBilling] = React.useState(false);
    const [totalPurchase, setTotalPurchase] = React.useState(0);
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [currentPrice, setCurrentPrice] = React.useState("price1");

    useEffect(() => {
      setPhoneNumber(props.currentUser.phone)

      const renderCartItems = () => {
        let _cartItems = []
  
        props.productItems.forEach(child =>{
          var currentIndex = props.cartItems.map(val => val.id).indexOf(child.id);
          if(currentIndex !== -1 && child.status === "published" && child.quantity > 0){
            _cartItems.push(props.cartItems[currentIndex])
          }
        })
  
        return _cartItems;
      }

      var itemsQuantity = 0;
      var _totalAmount_price1 = 0;
      var _totalAmount_price2 = 0;
      renderCartItems().forEach(val => {
        itemsQuantity += 1*val.quantity
        _totalAmount_price1 += val.sale_price*val.quantity
        _totalAmount_price2 += val.sale_price_2*val.quantity
      })

      var total_purchase = props.currentUser.total_ordered_products ? props.currentUser.total_ordered_products + itemsQuantity : itemsQuantity;
      setTotalPurchase(total_purchase)

      
      var now = moment();
      var expired_date = moment(props.currentUser.last_purchased).add(60, 'days');

      if(now.isBefore(expired_date) && total_purchase > 499){
        setCurrentPrice("price2")
        setTotalAmount(_totalAmount_price2)
      }
      else{
        setCurrentPrice("price1")
        setTotalAmount(_totalAmount_price1)
      }

    }, [props]);

    const saveAddressHandle = (address) => {

      var updates = {};

      if(props.currentUser.address){

        let totalAddress = [];

        props.currentUser.address.forEach((val, index)=>{
          if(address.type === val.type){
            totalAddress.push(val)
          }
        })

        if(totalAddress.length === 0){
          address.selected = true;
        }
        props.currentUser.address.push(address)

        if(address.selected){
          props.currentUser.address.forEach((val, index)=>{
            if(address.type === val.type && val !== address){
              val.selected = false;
            }
          })
        }

        updates['agent/' + props.currentUser.id] = props.currentUser;
        
        firebase.database().ref().update(updates);
      }
      else{
        address.selected = true;
        props.currentUser.address = [address]

        updates['agent/' + props.currentUser.id] = props.currentUser;
        
        firebase.database().ref().update(updates);
      }

      setOpenDialog(false)
    }

    const saveEditAddressHandle = (oldAddress, newAddress) => {
      var currentIndex = props.currentUser.address.map(val=> { return val}).indexOf(oldAddress);

      if(currentIndex !== -1){
        props.currentUser.address[currentIndex] = newAddress;
      }

      if(props.currentUser.address[currentIndex].selected){
        props.currentUser.address.forEach((val, index)=>{
          if(newAddress.type === val.type && val !== newAddress){
            val.selected = false;
          }
        })
      }

      var updates = {};
      updates['agent/' + props.currentUser.id] = props.currentUser;
        
      firebase.database().ref().update(updates);

      setOpenDialog(false)
    }
    
    const placeOrderHandle = () => {
      setMediaOption("place_order")
      setDialogLoading(false)
      setAgreeButton(true)
      setDialogTitle("Confirm Order")
      setOptions("place_order")
      setDialogDes("Are you sure would like to place an orders? The seller will be notified of your request immediately!")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

    const removeAddress = (val) => {
      setSelectedAddress(val)
      setMediaOption("remove_address")
      setDialogLoading(false)
      setAgreeButton(true)
      setDialogTitle("Remove Address")
      setOptions("removeItem")
      setDialogDes("Are you sure want to remove this address?")
      setFullScreenDialog(false)
      setOpenDialog(true)
    }

    const selectAddress = (type, value) => {

      props.currentUser.address.forEach((val)=>{
        if(val.type === type){
          if(val === value){
            val["selected"] = true;
          }
          else
          {
            val["selected"] = false;
          }
        }
        
      })

      var _currentUser = {...props.currentUser};

      props.setCurrentUser(_currentUser)
    }

    const renderComponent = () => {
      if(mediaOption === "address_billing"){
        return <AddAddress address={address} type={"billing"} 
        saveEditAddressHandle={(oldAddress, newAddress) => saveEditAddressHandle(oldAddress, newAddress)} 
        saveAddressHandle={(address)=> saveAddressHandle(address)}/>
      }
      else if(mediaOption === "address_shipping"){
        return <AddAddress address={address} type={"shipping"} 
        saveEditAddressHandle={(oldAddress, newAddress) => saveEditAddressHandle(oldAddress, newAddress)} 
        saveAddressHandle={(address)=> saveAddressHandle(address)}/>
      }
    }

    const addAddress = (type) => {
      setMediaOption("address_" + type)
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle("Add Address")
      setOptions("addItem")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
      setAddress(null)
    }

    const editAddress = (type, val) => {
      setMediaOption("address_" + type)
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle("Edit Address")
      setOptions("editItem")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
      setAddress(val)
    }

    const handleClose = () => {
      setOpenDialog(false)
    };

    const handleAgree = () => {

      var updates = {};

      if(mediaOption === "remove_address"){
        
        let currentAddress = props.currentUser.address.indexOf(selectedAddress);

        if (currentAddress > -1) {
          props.currentUser.address.splice(currentAddress, 1);
        }

        updates['agent/' + props.currentUser.id] = props.currentUser;
        
        firebase.database().ref().update(updates);

      }
      else if(mediaOption === "place_order"){
        
        setLoading(true)

        let orderUUIDRef = firebase.database().ref("orders_count")

        orderUUIDRef.once('value', snapshot => {
          if(snapshot.exists()){
            let val = snapshot.val()

            let newUUID = Number(val.uuid)+1
            saveOrder(newUUID)
            firebase.database().ref("orders_count").update({ uuid: newUUID })
          }
          else{
            saveOrder(1)
            firebase.database().ref("orders_count").update({ uuid: 1 })
          }

        })
        
      }

      setOpenDialog(false)
    }

    const saveOrder = (num) => {

        var updates = {};
        var updates_notification = {};

        var addItem = {};
        var newPostKey = firebase.database().ref().child("orders").push().key;

        var addNotification = {};
        var newNotificationKey = firebase.database().ref().child("notification").push().key;

        addItem['created'] = firebase.database.ServerValue.TIMESTAMP;
        addItem['notification_id'] = newNotificationKey;
        addItem['id'] = newPostKey;
        addItem['uuid'] = "INV"+ parseInt(num).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false});
        addItem['user'] = props.currentUser;
        addItem['user_uid'] = props.currentUser.id;
        addItem['products'] = renderCartItems();
        addItem['amount'] = totalAmount;
        addItem['phone_number'] = phoneNumber;
        addItem['status_orders'] = "Order received";
        addItem['status_orders_serial'] = 1;
        addItem['coupon'] = currentCoupon;
        addItem['service_by'] = props.currentSalesman;
        addItem['current_price'] = currentPrice

        var billing_address = "";
        var shipping_address = "";

        if(props.currentUser.address){
          props.currentUser.address.forEach((val)=>{
            if(val.type === "billing" && val.selected){
              billing_address = val;
            }
            
            if(val.type === "shipping" && val.selected){
              shipping_address = val;
            }
          })
        }

        addNotification['created'] = firebase.database.ServerValue.TIMESTAMP;
        addNotification['id'] = newNotificationKey;
        addNotification['data'] = "orders"; 
        addNotification['orders_id'] = newPostKey;
        addNotification['uuid'] = "INV"+ parseInt(num).toLocaleString('en-US', {minimumIntegerDigits: 6, useGrouping:false});
        addNotification['user'] = props.currentUser;
        addNotification['user_uid'] = props.currentUser.id;
        

        if(billing_address === ""){
          setErrorBilling(true)
        }
        else
        {
          setErrorBilling(false)
        }

        if(shipping_address === ""){
          setErrorShipping(true)
        }
        else
        {
          setErrorShipping(false)
        }


        var newPoint = 0;
        var itemsQuantity = 0;
        renderCartItems().forEach(val => {
          if(val.point){
            newPoint += val.point*val.quantity
            itemsQuantity += 1*val.quantity
          }
        })


        if(billing_address !== "" && shipping_address !== ""){

          addItem['billing_address'] = billing_address;
          addItem['shipping_address'] = shipping_address;
          
  
          updates["orders/" + newPostKey] = addItem;
          updates_notification["notification/" + newNotificationKey] = addNotification;

          firebase.database().ref().update(updates).then(()=>{
            
            props.currentUser.point_users = (props.currentUser.point_users ? Number(props.currentUser.point_users) : 0) + newPoint;

            if(props.currentSalesman){
              props.currentSalesman.total_sell_products =  (props.currentSalesman.total_sell_products ? Number(props.currentSalesman.total_sell_products) : 0) + itemsQuantity;
              props.currentSalesman.last_sell = firebase.database.ServerValue.TIMESTAMP;

              var salesman_updates = {}
              salesman_updates['salesman/' + props.currentSalesman.id] = props.currentSalesman;

              firebase.database().ref().update(salesman_updates);
            }

            props.currentUser.total_ordered_products =  (props.currentUser.total_ordered_products ? Number(props.currentUser.total_ordered_products) : 0) + itemsQuantity;
            props.currentUser.last_purchased = firebase.database.ServerValue.TIMESTAMP;

            var users_updates = {}
            users_updates['agent/' + props.currentUser.id] = props.currentUser;

            

            firebase.database().ref().update(users_updates).then(()=>{
              firebase.database().ref().update(updates_notification).then(()=>{
                history.push("/myorders/"+ newPostKey)
              })
            });
            
          })
  
          props.setItem([], props.currentUser)

        }
        else
        {
          setLoading(false)

        }
    }

    const handleCoupons = () => {
      
      if(coupon_code.current.value.length > 0){
        
        let itemRef = firebase.database().ref("coupons");

        itemRef.on('value', snapshot => {
          
          if(snapshot.exists()){

            let items = null;

            snapshot.forEach(child => {
                
              var itemsVal = child.val();

              if(itemsVal.coupon_code === coupon_code.current.value)
              {
                items = itemsVal;
              }
              
            })

            setCurrentCoupon(items)
          }
          else{
            setCurrentCoupon(null)
          }
          

        })

      }
    }

    const renderAddressLayout = (type) => {
      return(
        <Grid style={{ backgroundColor: "#424242", padding: 30, marginTop: 20, borderRadius: 5}}>

            <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Grid style={{ backgroundColor: "#99d700", borderRadius: 50, width: 30, height: 30, 
                display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography style={{ color: "black"}}>
                    3
                  </Typography>
                </Grid>
                {type === "shipping" ? <Typography style={{ marginLeft: 10 }}>
                  Shipping Address
                </Typography> : <Typography style={{ marginLeft: 10 }}>
                  Billing Address
                </Typography>}
              </Grid>
              <Grid>
                <IconButton size="small" onClick={()=>addAddress(type)}>
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: 20 }}>
              {props.currentUser.address ? 
              props.currentUser.address.map((val, index)=> {

                let colorBorder = "1px solid " + (val.selected ? "#99d700": "#7e7e7e");

                return(
                  <Grid item md={4} xs={6} key={type + "-" + index} style={{ display: val.type === type ? "flex": "none"}}>
                      
                      <Grid style={{ border:colorBorder, borderRadius: 5, minHeight: 120, width: "100%" }}>
                        <Grid style={{ display: "flex", justifyContent: "flex-end", width: "100%", padding: 10 }}>
                          <IconButton style={{ marginRight: 10 }} size="small" onClick={()=>editAddress(type, val)} >
                            <EditIcon  style={{ fontSize: 12 }} />
                          </IconButton>
                          <IconButton size="small" onClick={()=> removeAddress(val)}>
                            <CloseIcon  style={{ fontSize: 12 }} />
                          </IconButton>
                        </Grid>
                        <ListItem onClick={()=> selectAddress(type, val)} button style={{ padding: 15 }}>
                          <Grid>
                            <Typography variant={"body2"}>
                            {val.title}
                            </Typography>
                            <Grid style={{ marginTop: 10, opacity:.7, overflow: "hidden", textOverflow: "ellipsis" }}>
                              <Typography variant={"caption"} >
                              {val.street_address + ", " + val.city + ", " + val.zip + " " + val.state + ", " + val.country}
                            </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                        
                      </Grid>

                  </Grid>
                )
                
              })
                : null}
            </Grid>
            <Grid>
              {type === "shipping" && errorShipping ? <Typography style={{ marginTop: 15, fontSize: 12 }} variant={"body2"} color="secondary" >
                {"Please select at least one shipping address"}
              </Typography>: null}
              {type === "billing" && errorBilling ? <Typography style={{ marginTop: 15, fontSize: 12 }} variant={"body2"} color="secondary" >
                {"Please select at least one billing address"}
              </Typography>: null}
            </Grid>
          </Grid>
      )
    }

    const renderCartItems = () => {
      let _cartItems = []

      props.productItems.forEach(child =>{
        var currentIndex = props.cartItems.map(val => val.id).indexOf(child.id);
        if(currentIndex !== -1 && child.status === "published" && child.quantity > 0){
          _cartItems.push(props.cartItems[currentIndex])
        }
      })

      return _cartItems;
    }

    if(loading){
      return (
      <Grid className={classes.root}>
        <Grid style={{ marginTop: 70 }}>
          <CircularProgress 
            disableShrink
            color="primary" />
        </Grid>
      </Grid>);
    }

    return (
      <Grid className={classes.root}>
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          handleAgree={handleAgree}
          component={renderComponent()}
          handleClose={handleClose}/>

        <Grid container style={{ maxWidth: 1200 }}>
          <Grid item md={7} xs={12} style={{ padding : 20 }}>
              <Grid style={{ backgroundColor: "#424242", height: 150, padding: 30, borderRadius: 5 }}>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <Grid style={{ backgroundColor: "#99d700", borderRadius: 50, width: 30, height: 30, 
                    display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Typography style={{ color: "black"}}>
                        1
                      </Typography>
                    </Grid>
                    <Typography style={{ marginLeft: 10 }}>
                      Contact Number
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <PhoneInput
                    containerStyle={{ marginTop:20 }}
                    dropdownStyle={{ color: "#1c1c1c" }}
                    disabled
                    country={'my'}
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                  />
                </Grid>
              </Grid>
              {renderAddressLayout("billing")}
              {renderAddressLayout("shipping")}

          </Grid>
          <Grid item md={5} xs={12}>
              <Grid style={{ padding: 30, display: "flex", alignItems: "center", flexDirection: "column"  }}>
                <Typography variant={"h6"}  style={{ opacity: .7 }}>
                    Your Order
                </Typography>
                <Grid container style={{ marginTop: 20 }}>
                    {renderCartItems().map((val, index)=>{
                      
                      return(
                        <Grid key={"items-" + index} item xs={12} style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row"}}>
                            <Grid style={{ display:"flex", flexDirection: "row"}}>
                              <Typography variant={"body2"} style={{ opacity: .9, width: 30, fontWeight: "bold" }}>
                                  {val.quantity}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .7, width: 20 }}>
                                  {"x"}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .5, width: 150 }}>
                                  {val.name}
                              </Typography>
                            </Grid>

                            <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                              <Typography align="right" variant={"body2"} style={{ opacity: .7, width: 100, }}>
                                  {"RM " + (totalPurchase <= 499 ? val.sale_price : val.sale_price_2) * val.quantity}
                              </Typography>
                            </Grid>
                        </Grid>
                      )
                    })}
                </Grid>

                <Grid container style={{ width: "100%", alignItems: "center", marginTop:20 }}>

                  <Grid item xs={9}>
                    <TextField
                      inputRef={coupon_code}
                      variant="outlined"
                      margin="none"
                      autoComplete='off'
                      required
                      fullWidth
                      id="coupon"
                      name="Coupon"
                      size="small"
                      style={{ width: "100%", paddingRight: 20}}
                      label="Enter Coupon Code Here"
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button onClick={handleCoupons} style={{ width: "100%" }} color="primary" disableElevation variant="contained">
                      Apply
                    </Button>
                  </Grid>

                </Grid>

                {currentCoupon ? <Grid container style={{ width: "100%", alignItems: "center", marginTop:10 }}>
                  <Grid item xs={6}>
                    <Typography  align="left" variant={"body2"} style={{ opacity: .7 }}>
                        {currentCoupon.name + " applied"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end"}}>
                    <Button onClick={()=> setCurrentCoupon(null)} style={{ opacity: .7, }}>
                        {"remove"}
                    </Button>
                  </Grid>
                </Grid> :  null}

                {currentCoupon && currentCoupon.coupon_type === "free_item" ? 
                <Grid container style={{ width: "100%", alignItems: "center", marginTop: 10 }}>
                  {currentCoupon.free_products.map((val, index)=>{
                      return(
                        <Grid key={"items-" + index} item xs={12} style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row"}}>
                            <Grid style={{ display:"flex", flexDirection: "row"}}>
                              <Typography variant={"body2"} style={{ opacity: .9, width: 30, fontWeight: "bold" }}>
                                  {val.quantity}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .7, width: 20 }}>
                                  {"x"}
                              </Typography>
                              <Typography variant={"body2"} style={{ opacity: .5, width: 150 }}>
                                  {val.name}
                              </Typography>
                            </Grid>

                            <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                              <Typography align="right" variant={"body2"} style={{ opacity: .7, width: 100, }}>
                                  {"FREE"}
                              </Typography>
                            </Grid>
                        </Grid>
                      )
                    })}
                </Grid> :  null}

                <Divider style={{width: "100%", marginTop: 20}}/>
                <Grid style={{ marginTop: 20,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"body2"} style={{ opacity: .9 }}>
                        {"Sub Total"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"body2"} style={{ opacity: .7, }}>
                        {"RM " + totalAmount}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"body2"} style={{ opacity: .9 }}>
                        {"Tax"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"body2"} style={{ opacity: .7, }}>
                        {"RM 0"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid style={{ marginTop: 10,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"body2"} style={{ opacity: .9 }}>
                        {"Shipping"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"body2"} style={{ opacity: .7, }}>
                        {"Pending"}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider style={{width: "100%", marginTop: 20}}/>

                <Grid style={{ marginTop: 20,  display:"flex", justifyContent: "space-between", flexDirection: "row", width: "100%"}}>
                  <Grid style={{ display:"flex", flexDirection: "row"}}>
                    <Typography variant={"h6"} style={{ opacity: .9,  fontWeight: "bold" }}>
                        {"Total"}
                    </Typography>
                  </Grid>
                  <Grid style={{ display:"flex", justifyContent: "flex-end" }}>
                    <Typography align="right" variant={"h6"} style={{ opacity: .7, }}>
                        {"RM " + totalAmount}
                    </Typography>
                  </Grid>
                </Grid>
                  
                {/*<Grid style={{ width: "100%", marginTop: 10 }} >
                  <Typography align="left" variant={"body2"} style={{ opacity: .7, }}>
                        {"Direct Bank Transfer"}
                  </Typography>
                  <Typography align="left" variant={"body2"} style={{ opacity: .4, fontSize: 12}}>
                        {"Bank transfer (within 24 hours or subjected to cancellation) → screenshot of proof of payment → Whatsapp to +6012-345678 / email to admin@sp2smalaysia.com with title “payment proof for order ____”"}
                  </Typography>
                  </Grid>*/}

                <Button onClick={placeOrderHandle} disabled={renderCartItems().length === 0 ? true: false} style={{ marginTop: 30, width: "100%" }} color="primary" disableElevation variant="contained">
                  Place Order
                </Button>
              </Grid>
              
          </Grid>
        </Grid>
      </Grid>
    );
  
}

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  productItems: selectProductItems,
  total: selectCartTotal,
  currentUser: selectCurrentUser,
  currentSalesman: selectCurrentSalesman,
})

const mapDispatchToProps = dispatch => ({
  setCurrentUser: item => dispatch(setCurrentUser(item)),
  setItem: (item, user) => dispatch(setItem(item, user))
});

export default connect( mapStateToProps, mapDispatchToProps )(Index);