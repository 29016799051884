import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopBanner from './Main/TopBanner';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import firebase from "firebase";

//card
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux'
import { selectCurrentUser } from '../../redux/user/user.selector'
import { createStructuredSelector } from 'reselect'

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  media: {
    height: 0,
    paddingTop: '60%', // 16:9
  }
}))
  
function Index(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [coupons, setCoupons] = React.useState([]);
  const [copied, setCopied] = React.useState(null);

  useEffect(() => {
    let itemRef = firebase.database().ref("coupons");

    itemRef.on('value', snapshot => {
      
      if(snapshot.exists()){

        let items = [];

        snapshot.forEach(child => {
            
          var itemsVal = child.val();

          if(itemsVal.min_point){
            if(Number(props.currentUser.point_users) >= Number(itemsVal.min_point) ){
              items.push(itemsVal);
            }
          }
          else
          {
            items.push(itemsVal);
          }
          
        })

        setCoupons(items)
      }
      else{
        setCoupons([])
      }

      setLoading(false)
    })

  }, [props]);

  const handleCopy = (index, code) => {
    
    navigator.clipboard.writeText(code).then(
      () => {
        setCopied(index)
      },
      () => {
        setCopied(null)
      }
    );
  }

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }

  return (
    <Grid className={classes.root}>
      <TopBanner/>
      <Grid style={{ padding: 20, width: "100%" }}>
        <Grid container spacing={2}>
            {coupons.map((val,index)=>{
              return(
                <Grid item xs={6} md={4} lg={3} key={"coupon-"+index}>
                  <Card style={{ width: "100%"}}>

                          <CardMedia
                            component="div"
                            className={classes.media}
                            image={val.image ? val.image[0].url : null}
                            title={val.name}
                          />
                          <CardContent>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                  <Typography style={{ fontSize: 20, 
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                    }} variant="body2" color="textSecondary" component="p">
                                      {val.coupon_code}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                  <Typography style={{ opacity: .5, fontSize: 14,
                                      display: '-webkit-box',
                                      WebkitBoxOrient: 'vertical',
                                      WebkitLineClamp: 2,
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis'
                                      }} variant="body2" color="textSecondary" component="p">
                                        {val.description}
                                      </Typography>
                                </Grid>
                                
                                <Grid item xs={12} lg={12}>
                                  <Typography style={{ opacity: .5, fontSize: 14,
                                    height: 20,
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                    }} variant="body2" color="textSecondary" component="p">
                                      {val.name}
                                    </Typography>
                                </Grid>

                              </Grid>
                          </CardContent>

                        <CardActions style={{ display: "flex", justifyContent: "space-between", padding: 0}}>
                            <Button onClick={()=> handleCopy(index, val.coupon_code)} style={{ fontSize: 16, width: "100%", borderStartStartRadius: 0, borderStartEndRadius: 0 }} color={copied ===  index ? "primary" : "default"} disableElevation variant="contained" size="small">
                                {copied ===  index ? "Copied":"Copy"}
                            </Button>
                        </CardActions>
                  </Card>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStatetoProps = createStructuredSelector({
  currentUser: selectCurrentUser,
})

export default connect(mapStatetoProps)(Index);
