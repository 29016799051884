import { createSelector } from 'reselect'

const selectRedeem = state => state.redeem


export const selectRedeemItems = createSelector(
    [selectRedeem],
    redeem => {
      return redeem.redeemItems}
)


export const selectRedeemHidden = createSelector(
    [selectRedeem],
    (redeem)=> redeem.hidden
)

export const selectRedeemItemsCount = createSelector(
    [selectRedeemItems],
    redeemItems =>
        (redeemItems.reduce(
            (accumulatedQuantity, redeemItem) =>
                accumulatedQuantity + redeemItem.quantity,
            0
        )
        )
)

export const selectRedeemTotal = createSelector(
    [selectRedeemItems],
    redeemItems =>
        (redeemItems.reduce(
            (accumulatedQuantity, redeemItem) =>
                accumulatedQuantity + redeemItem.quantity * redeemItem.sale_price,
            0
        )
        )
)