import RedeemActionTypes from './redeem.types'

export const toggleRedeemHidden = () => ({
    type: RedeemActionTypes.TOGGLE_REDEEM_HIDDEN
})

export const setRedeemItem = (item, user) => ({
    type: RedeemActionTypes.SET_REDEEM_ITEM,
    payload: item,
    user: user
})

export const addRedeemItem = (item, user) => ({
    type: RedeemActionTypes.ADD_REDEEM_ITEM,
    payload: item,
    user: user
})

export const changeRedeemItemQuantity = (quantity, item, user) => ({
    type: RedeemActionTypes.CHANGE_REDEEM_ITEM_QUANTITY,
    payload: item,
    quatity: quantity,
    user: user
})

export const clearRedeemItemFromRedeem = (item, user) => ({
    type: RedeemActionTypes.CLEAR_REDEEM_ITEM_FROM_REDEEM,
    payload: item,
    user: user
})


export const removeRedeemItem = (item, user) =>({
    type: RedeemActionTypes.REMOVE_REDEEM_ITEM,
    payload: item,
    user: user
})
