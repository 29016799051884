import { combineReducers } from 'redux'

import userReducer from './user/user.reducer'
import salesmanReducer from './salesman/salesman.reducer'
import cartReducer from './cart/cart.reducer'
import redeemReducer from './redeem/redeem.reducer'
import productReducer from './product/product.reducer'
import categoryReducer from './category/category.reducer'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart']
}

const rootReducer = combineReducers({
    user: userReducer,
    salesman: salesmanReducer,
    cart: cartReducer,
    redeem: redeemReducer,
    product: productReducer,
    category: categoryReducer
})

export default persistReducer(persistConfig, rootReducer)