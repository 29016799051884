import React, { useEffect } from 'react';
import TopBanner from './Main/TopBanner';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
  
function Index(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(false)
  }, []);

  if(loading){
    return (
    <Grid className={classes.root}>
      <Grid style={{ marginTop: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>
    </Grid>);
  }

  return (
    <Grid className={classes.root}>
      <TopBanner/>
      <Grid style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Grid style={{ maxWidth: 1000, padding: 20 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.secondaryHeading}>What should I do if my package is damaged during shipment?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='caption' style={{ opacity: .5 }}>
              In addition to the outer packaging, our products are additionally equipped with an internal protective film. If the package is damaged, open it to see if the product is damaged. If it is not damaged, you can still use it. If the product is damaged, please contact our customer service by sending an email to admin@sp2smalaysia.com within 48 hours after receiving the product, and our customer service will reply to your email within 24hrs.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.secondaryHeading}>What shall I do if I still have questions about product information before I place an order?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='caption' style={{ opacity: .5 }}>
              Please send your questions to our 24/7 customer service email: admin@sp2smalaysia.com.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.secondaryHeading}>How long is the shelf life of a pod?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant='caption' style={{ opacity: .5 }}>
              The shelf life of the pod is 1 year only when it is stored correctly and unopened. We recommend to finish the product 15 days after opening.
For SP2s Blitz Pod, the shelf life is up to 18 months when stored unopened in a cool and dry place.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      
    </Grid>
  );
}

export default Index;