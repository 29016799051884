import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));
  
function Index(props) {

  const classes = useStyles();

  const [errorAddress, setErrorAddress] = React.useState({
    title: false,
    country: false,
    city: false,
    state: false,
    zip: false,
    street_address: false
  });

  const [address, setAddress] = React.useState({
    type: props.type,
    title: "",
    country: "",
    city: "",
    state: "",
    zip: "",
    street_address: ""
  });

  useEffect(() => {
    if(props.address){
      setAddress(props.address)
    }
  }, [props]);

  const handleAddressChange = (prop) => (event) => {
    setAddress({ ...address, [prop]: event.target.value });
  };

  const handleCheckBoxChange = (prop) => (event) => {
    setAddress({ ...address, [prop]: event.target.checked });
  };

  const handleSubmit = () => {
    
    let allPass = true;
    let _title = false;
    let _country = false;
    let _city = false;
    let _state = false;
    let _zip = false;
    let _street_address = false;

    if(address.title.length === 0){
      _title = true;
      allPass = false;
    }

    if(address.country.length === 0){
      _country = true;
      allPass = false;
    }

    if(address.city.length === 0){
      _city = true;
      allPass = false;
    }

    if(address.country.length === 0){
      _city = true;
      allPass = false;
    }

    if(address.state.length === 0){
      _state = true;
      allPass = false;
    }

    if(address.zip.length === 0){
      _zip = true;
      allPass = false;
    }

    if(address.street_address.length === 0){
      _street_address = true;
      allPass = false;
    }

    let _newErrorAddress = {
      title: _title,
      country: _country,
      city: _city,
      state: _state,
      zip: _zip,
      street_address: _street_address
    }
    
    setErrorAddress(_newErrorAddress)

    if(allPass){

      if(props.address){
        props.saveEditAddressHandle(props.address, address)
      }
      else
      {
        props.saveAddressHandle(address)
      }

    }
    
  }

  return (
    <Grid className={classes.root}>
      <Grid container spacing={2} style={{ width: "100%", maxWidth: 500, padding: 20, marginBottom: 20 }}>
            <Grid item style={{ width: "100%" }} >
              <Typography style={{ color : "white"}} variant="caption" noWrap={true}>{"Address"}</Typography>
            </Grid>
            <Grid item md={12} style={{ width: "100%" }} >
                <TextField
                        error={errorAddress.title}
                        label={"Title"}
                        style={{ width: "100%"}}
                        onChange={handleAddressChange("title")}
                        value={address.title}
                        variant="outlined"/>
            </Grid>
            <Grid item md={12} style={{ width: "100%" }} >
                <TextField
                        multiline
                        error={errorAddress.street_address}
                        label={"Street Address"}
                        style={{ width: "100%"}}
                        onChange={handleAddressChange("street_address")}
                        value={address.street_address}
                        variant="outlined"/>
            </Grid>
            <Grid item md={6} xs={12} style={{ width: "100%" }} >
                <TextField
                        error={errorAddress.country}
                        label={"Country"}
                        style={{ width: "100%"}}
                        onChange={handleAddressChange("country")}
                        value={address.country}
                        variant="outlined"/>
            </Grid>
            <Grid item md={6} xs={12} style={{ width: "100%" }} >
                <TextField
                        error={errorAddress.city}
                        label={"City"}
                        style={{ width: "100%"}}
                        onChange={handleAddressChange("city")}
                        value={address.city}
                        variant="outlined"/>
            </Grid>
            <Grid item md={6} xs={12} style={{ width: "100%" }} >
                <TextField
                        error={errorAddress.state}
                        label={"State"}
                        style={{ width: "100%"}}
                        onChange={handleAddressChange("state")}
                        value={address.state}
                        variant="outlined"/>
            </Grid>
            <Grid item md={6} xs={12} style={{ width: "100%" }} >
                <TextField
                        error={errorAddress.zip}
                        label={"ZIP"}
                        style={{ width: "100%"}}
                        onChange={handleAddressChange("zip")}
                        value={address.zip}
                        type={"number"}
                        variant="outlined"/>
            </Grid>
            
            <Grid item md={12} style={{ width: "100%" }} >
                <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={address.selected ? true : false} onChange={handleCheckBoxChange("selected")} name="Default" />}
                      label="Set Default"
                    />
                </FormGroup>
            </Grid>
            <Grid item md={12} style={{ width: "100%" }} >
              <Button onClick={handleSubmit} style={{ marginTop: 30, width: "100%" }} color="primary" disableElevation variant="contained">
                Save Address
              </Button>
            </Grid>
        </Grid>
    </Grid>
  );
}

export default Index;