import { SalesmanActionTypes } from './salesman.types'



const INITIAL_STATE = {
    currentSalesman: null
}
const salesmanReducer = (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
        case SalesmanActionTypes.SET_CURRENT_SALESMAN:
            return {
                ...state,
                currentSalesman: action.payload
            }

        default:
            return state
    }
}

export default salesmanReducer