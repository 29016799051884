export const addItemToCart = (cartItems, cartItemToAdd) => {
    const existingCartItem = cartItems.find(cartItem => cartItem.id === cartItemToAdd.id)

    if (existingCartItem) {
        return cartItems.map(cartItem => cartItem.id === cartItemToAdd.id ?
            { ...cartItem, quantity: parseInt(cartItem.quantity) + 1 } : cartItem)
    }

    return [...cartItems, { ...cartItemToAdd, quantity: 1 }]
}

export const changeItemQuatityToCart = (cartItems, cartItemToAdd, quantity) => {
    const existingCartItem = cartItems.find(cartItem => cartItem.id === cartItemToAdd.id)

    if (existingCartItem) {

        if(quantity < 1){
            quantity = 1
        }

        return cartItems.map(cartItem => cartItem.id === cartItemToAdd.id ?
            { ...cartItem, quantity: quantity } : cartItem)
    }
    

    return [...cartItems, { ...cartItemToAdd, quantity: quantity }]
}


export const removeItemFromCart = (cartItems, cartItemToRemove) => {
    const existingCartItem = cartItems.find(cartItem => cartItem.id === cartItemToRemove.id)

    if (existingCartItem.quantity === 1) {
        return cartItems.filter(cartItem => cartItem.id !== cartItemToRemove.id)
    }

    return cartItems.map(cartItem => cartItem.id === cartItemToRemove.id ? { ...cartItem, quantity: parseInt(cartItem.quantity) - 1 } : cartItem)
}