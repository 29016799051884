export const addItemToRedeem = (redeemItems, redeemItemToAdd) => {
    const existingRedeemItem = redeemItems.find(redeemItem => redeemItem.id === redeemItemToAdd.id)

    if (existingRedeemItem) {
        return redeemItems.map(redeemItem => redeemItem.id === redeemItemToAdd.id ?
            { ...redeemItem, quantity: parseInt(redeemItem.quantity) + 1 } : redeemItem)
    }

    return [...redeemItems, { ...redeemItemToAdd, quantity: 1 }]
}

export const changeItemQuatityToRedeem = (redeemItems, redeemItemToAdd, quantity) => {
    const existingRedeemItem = redeemItems.find(redeemItem => redeemItem.id === redeemItemToAdd.id)

    if (existingRedeemItem) {

        if(quantity < 1){
            quantity = 1
        }

        return redeemItems.map(redeemItem => redeemItem.id === redeemItemToAdd.id ?
            { ...redeemItem, quantity: quantity } : redeemItem)
    }
    

    return [...redeemItems, { ...redeemItemToAdd, quantity: quantity }]
}


export const removeItemFromRedeem = (redeemItems, redeemItemToRemove) => {
    const existingRedeemItem = redeemItems.find(redeemItem => redeemItem.id === redeemItemToRemove.id)

    if (existingRedeemItem.quantity === 1) {
        return redeemItems.filter(redeemItem => redeemItem.id !== redeemItemToRemove.id)
    }

    return redeemItems.map(redeemItem => redeemItem.id === redeemItemToRemove.id ? { ...redeemItem, quantity: parseInt(redeemItem.quantity) - 1 } : redeemItem)
}