import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import Carousel from 'react-material-ui-carousel'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { red } from '@material-ui/core/colors';

import Dialog from '../../../modules/components/Dialog';

import { connect } from 'react-redux';

import {
    addItem,
    removeItem,
    changeItemQuantity
} from '../../../redux/cart/cart.actions';

import {
  addRedeemItem,
  removeRedeemItem,
  changeRedeemItemQuantity
} from '../../../redux/redeem/redeem.actions';

import { createStructuredSelector } from 'reselect';
import { selectRedeemItems, selectRedeemTotal } from '../../../redux/redeem/redeem.selectors';
import { selectCartItems } from '../../../redux/cart/cart.selectors';
import { selectProductItems } from '../../../redux/product/product.selectors';
import { selectCategoryItems } from '../../../redux/category/category.selectors';
import { selectCurrentUser } from '../../../redux/user/user.selector'

import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  }
}));

const lightColor = 'rgba(255, 255, 255, 0.7)';
const maxPod = 50;
const maxDevice = 10;

function Index(props) {

    //Dialog
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogLoading, setDialogLoading] = React.useState(false);
    const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("");
    const [dialogDes, setDialogDes] = React.useState("");
    const [agreeButton, setAgreeButton] = React.useState(false);
    const [option, setOptions] = React.useState("");
    const [mediaOption, setMediaOption] = React.useState("products");
    const [dialogMaxWidth, setDialogMaxWidth] = React.useState(null);

    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [currentCategory, setCurrentCategory] = React.useState(null);
    const [currentProduct, setCurrentProduct] = React.useState({});
    const [enableCategories, setEnableCategories] = React.useState(true);

    const [totalPurchase, setTotalPurchase] = React.useState(0);
    
    useEffect(() => {

      if(props.match.params.id === "redeem_products"){
        setEnableCategories(false)
      }
      else
      {
        setEnableCategories(true)
      }

      var itemsQuantity = 0;
      props.cartItems.forEach(val => {
        itemsQuantity += 1*val.quantity
      })

      var total_purchase = props.currentUser.total_ordered_products ? props.currentUser.total_ordered_products + itemsQuantity : itemsQuantity;
      setTotalPurchase(total_purchase)


      setLoading(false)
      
    }, [props]);

    const handleClose = () => {
      setOpenDialog(false)
    };

    const renderRedeemItems = () => {
      let _redeemItems = []

      props.productItems.forEach(child =>{
        var currentIndex = props.redeemItems.map(val => val.id).indexOf(child.id);
        if(currentIndex !== -1 && child.status === "published" && child.redeem && child.quantity > 0){
          _redeemItems.push(props.redeemItems[currentIndex])
        }
      })

      return _redeemItems;
    }

    const renderCategories = () => {
      let _categoryItems = [];

      var itemsCategoriesSet = new Set();

      renderItemsProducts().forEach(val =>{

        if(val.categories && val.categories.length > 0){
          val.categories.forEach(value => {
            itemsCategoriesSet.add(value.id);
          })
        }
        
      })

      let itemsCategories = Array.from(itemsCategoriesSet);

      itemsCategories.forEach(id =>{
        var currentIndex = props.categoryItems.map(val => val.id).indexOf(id);
        if(currentIndex !== -1){
          _categoryItems.push(props.categoryItems[currentIndex])
        }
      })

      return _categoryItems;
    }

    const renderItemsProducts = () => {
       
        let _productItems = [];

        if(props.match.params.id === "redeem_products"){

          props.productItems.forEach(val =>{
            if(val.status === "published" && val.redeem && val.quantity > 0 && val.type === props.type){
              _productItems.push(val)
            }
          })

        }
        else{
          /*props.productItems.forEach(val =>{
            if(val.status === "published" && val.quantity > 0 ){
              _productItems.push(val)
            }
          })*/
        }
        


        return _productItems;
    }

    const renderListProducts = () => {

      return(
        <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%",padding: 10 }}>
            
            {renderItemsProducts().sort((a, b) => a.name.localeCompare(b.name)).map((val, index) => {
              var currentProductIndex = (enableCategories ? props.cartItems : renderRedeemItems()).map(val=> val.id).indexOf(val.id);

              var currentCategoriesIndex = 1;
              
              if(currentCategory){
                if(val.categories){
                  currentCategoriesIndex = val.categories.map(value => value.id).indexOf(currentCategory)
                }
                else
                {
                  currentCategoriesIndex = -1;
                }
              }
              

              return(
                <Grid style={{ display: currentCategoriesIndex === -1 ? "none":"flex",  padding: 10 }} key={"product-card-"+index} item xs={6} sm={4} md={3} lg={2} >
                  <Card className={classes.root}>
                      <CardActionArea onClick={()=> showProductDetails(val)}>
                        <CardMedia
                          component="div"
                          className={classes.media}
                          image={val.image ? val.image[0].url : null}
                          title={val.name}
                        />
                        <CardContent>
                          <Grid style={{ display: "flex", flexDirection: "column" }}>
                            <Typography style={{ fontSize: 12, opacity: .5, textDecoration: "line-through", height: 20 }} variant="caption" color="textSecondary">
                              {val.price ? "RM " + val.price : " "}
                            </Typography>
                            <Typography style={{ fontSize: 16, height: 20 }} variant="body2" color="textSecondary" component="p">
                              {totalPurchase <= 499 ? val.sale_price :  val.sale_price_2 }
                            </Typography>
                          </Grid>
                          
                          <Typography style={{ opacity: .5, fontSize: 14, 
                          height: 40,
                          marginTop: 10,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                          }} variant="body2" color="textSecondary" component="p">
                            {val.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions style={{ display: "flex", justifyContent: "space-between", padding: 0}}>
                        <Grid container style={{ width: "100%" }}>
                       
                          {currentProductIndex !== -1 ? <Grid item xs={5}>
                            <Button onClick={()=> enableCategories ?  removeFromCartHandle(val) : removeRedeemFromCartHandle(val)} color="primary" style={{  width: "100%", height: 30, borderRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              -
                            </Button>
                            </Grid>: null}

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={2} style={{ backgroundColor: "#7faa14",  display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                              <Input 
                               inputProps={{min:1, style: { textAlign: 'center' }}}
                              style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center", color: "black" }} 
                              onChange={(e)=> { 

                                var isNumber = /^\d+$/.test(e.target.value)
                                
                                if(enableCategories){
                                  if(!isNumber){
                                    return props.changeItemQuantity(e.target.value.replace(/\D/g,''), props.cartItems[currentProductIndex], props.currentUser)
                                  }
                                  else
                                  {
                                    return props.changeItemQuantity(e.target.value, props.cartItems[currentProductIndex], props.currentUser)
                                  }
                                }
                                else
                                {
                                  if(!isNumber){
                                    return props.changeRedeemItemQuantity(e.target.value.replace(/\D/g,''), renderRedeemItems()[currentProductIndex], props.currentUser)
                                  }
                                  else
                                  {

                                    if(renderRedeemItems()[currentProductIndex].type === "pod"){
                                      let totalQuantity = (getTotalPODs() - parseInt(renderRedeemItems()[currentProductIndex].quantity)) + parseInt(e.target.value);

                                      if(totalQuantity > maxPod){
                                        return props.changeRedeemItemQuantity(renderRedeemItems()[currentProductIndex].quantity, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                      else
                                      {
                                        return props.changeRedeemItemQuantity(e.target.value, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                    }

                                    else if(renderRedeemItems()[currentProductIndex].type === "device"){
                                      let totalQuantity = (getTotalDevices() - parseInt(renderRedeemItems()[currentProductIndex].quantity)) + parseInt(e.target.value);

                                      if(totalQuantity > maxDevice){
                                        return props.changeRedeemItemQuantity(renderRedeemItems()[currentProductIndex].quantity, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                      else
                                      {
                                        return props.changeRedeemItemQuantity(e.target.value, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                    }
                                    else
                                    {
                                      return props.changeRedeemItemQuantity(e.target.value.replace(/\D/g,''), renderRedeemItems()[currentProductIndex], props.currentUser)
                                    }
                                   
                                  }
                                }

                                
                               }} value={enableCategories ? props.cartItems[currentProductIndex].quantity : renderRedeemItems()[currentProductIndex].quantity} />
                            </Grid>: null}

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={5}>
                            <Button onClick={()=> enableCategories ? addToCartHandle(val): addToRedeemHandle(val)} color="primary" style={{ width: "100%", height: 30, borderRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              {"+"}
                            </Button>
                            </Grid>: null}

                            {currentProductIndex === -1 ?<Grid item xs={12}>
                            <Button onClick={()=> enableCategories ? addToCartHandle(val): addToRedeemHandle(val)} style={{ width: "100%", height: 30, borderRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              {enableCategories ? "Add to Cart" : "Select"}
                            </Button>
                            </Grid>: null}

                        </Grid>
                      </CardActions>
                    
                  </Card>
                </Grid>
              )
            })}
            {renderItemsProducts().length === 0 ? 
            <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70, width: "100%", flexDirection: "column" }} >
              <Typography variant="h4" color="textSecondary">
                No Products Found
              </Typography>
              <Typography variant="body2" style={{ opacity: .5 }} color="textSecondary">
                Your search did not match any products.
              </Typography>
            </Grid>
            
            : null}
          </Grid>
      )
    }

    const renderComponent = () => {

      if(mediaOption === "products"){
        var currentProductIndex = (enableCategories ? props.cartItems : renderRedeemItems()).map(val=> val.id ).indexOf(currentProduct.id);

        return(
          <Grid style={{ width: "100%", height: "100%" }}>
            <Grid container style={{ width: "100%"}}>
               <Grid item md={7} xs={12} style={{ display: "flex", justifyContent: "center", backgroundColor: "#333333", padding: 10 }}>

                {currentProduct.gallery ?
                <Carousel className={classes.root}>
                      {currentProduct.gallery.map((val, index) => <Card key={"gallery-" + (index+1)} className={classes.root}>
                        <CardMedia
                                  component="div"
                                  className={classes.media}
                                  image={val.url}
                                  title={val.name}
                                />
                      </Card> )}
                    
                </Carousel> : 
                <Carousel className={classes.root}>
                    <Card className={classes.root}>
                      <CardMedia
                                component="div"
                                className={classes.media}
                                image={currentProduct.image ? currentProduct.image[0].url : null}
                                title={currentProduct.name}
                              />
                    </Card>
              </Carousel>
                }

               </Grid>
               <Grid item md={5} xs={12} >
                 <Grid style={{ padding: 20 }}>
                   <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                     <Typography style={{ fontSize: 36 }} variant="body2" color="textSecondary" component="p">
                       {totalPurchase <= 499 ? currentProduct.sale_price: currentProduct.sale_price_2 }
                     </Typography>
                     <Typography style={{ fontSize: 18, marginLeft: 10, opacity: .5, textDecoration: "line-through" }} variant="caption" color="textSecondary">
                       {currentProduct.price ? "RM " + currentProduct.price : " "}
                     </Typography>
                   </Grid>
                   
                   <Typography style={{ opacity: .7, fontSize: 16 }} variant="body2" color="textSecondary" component="p">
                     {currentProduct.name}
                   </Typography>

                   <Typography component="div" style={{ opacity: .5, fontSize: 12, marginTop: 10  }} variant="body2" color="textSecondary">
                            <pre style={{ fontFamily: 'inherit', display: 'inline' , whiteSpace: "pre-line"}}>
                            {currentProduct.description}
                            </pre>
                   </Typography>

                   

                   {currentProductIndex !== -1 ? <Grid container style={{ width: "100%", marginTop: 20, borderRadius: 5 }}>

                   <Grid item xs={3}>
                            <Button onClick={()=> enableCategories ?  removeFromCartHandle(currentProduct) : removeRedeemFromCartHandle(currentProduct)} color="primary" style={{  width: "100%", height: 30, borderEndEndRadius: 0, borderStartEndRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              -
                            </Button>
                            </Grid>

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={6} style={{ backgroundColor: "#7faa14",  display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }} >
                              <Input 
                               inputProps={{min:1, style: { textAlign: 'center' }}}
                              style={{ fontSize: 14, width: 40, padding: 0, textAlign: "center", color: "black" }} 
                              onChange={(e)=> { 

                                var isNumber = /^\d+$/.test(e.target.value)
                                
                                if(enableCategories){
                                  if(!isNumber){
                                    return props.changeItemQuantity(e.target.value.replace(/\D/g,''), props.cartItems[currentProductIndex], props.currentUser)
                                  }
                                  else
                                  {
                                    return props.changeItemQuantity(e.target.value, props.cartItems[currentProductIndex], props.currentUser)
                                  }
                                }
                                else
                                {
                                  if(!isNumber){
                                    return props.changeRedeemItemQuantity(e.target.value.replace(/\D/g,''), renderRedeemItems()[currentProductIndex], props.currentUser)
                                  }
                                  else
                                  {

                                    if(renderRedeemItems()[currentProductIndex].type === "pod"){
                                      let totalQuantity = (getTotalPODs() - parseInt(renderRedeemItems()[currentProductIndex].quantity)) + parseInt(e.target.value);

                                      if(totalQuantity > maxPod){
                                        return props.changeRedeemItemQuantity(renderRedeemItems()[currentProductIndex].quantity, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                      else
                                      {
                                        return props.changeRedeemItemQuantity(e.target.value, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                    }

                                    else if(renderRedeemItems()[currentProductIndex].type === "device"){
                                      let totalQuantity = (getTotalDevices() - parseInt(renderRedeemItems()[currentProductIndex].quantity)) + parseInt(e.target.value);

                                      if(totalQuantity > maxDevice){
                                        return props.changeRedeemItemQuantity(renderRedeemItems()[currentProductIndex].quantity, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                      else
                                      {
                                        return props.changeRedeemItemQuantity(e.target.value, renderRedeemItems()[currentProductIndex], props.currentUser)
                                      }
                                    }
                                    else
                                    {
                                      return props.changeRedeemItemQuantity(e.target.value.replace(/\D/g,''), renderRedeemItems()[currentProductIndex], props.currentUser)
                                    }
                                   
                                  }
                                }

                               }} value={enableCategories ? props.cartItems[currentProductIndex].quantity : renderRedeemItems()[currentProductIndex].quantity} />
                            </Grid>: null}

                            {currentProductIndex !== -1 ? 
                            <Grid item xs={3}>
                            <Button onClick={()=> enableCategories ? addToCartHandle(currentProduct): addToRedeemHandle(currentProduct)} color="primary" style={{ width: "100%", height: 30,borderEndStartRadius: 0, borderStartStartRadius: 0, opacity: .7 }} disableElevation variant="contained" size="small">
                              {"+"}
                            </Button>
                            </Grid>: null}

                            
                    </Grid>: null}

                    {currentProductIndex === -1 ?
                    <Grid container style={{ width: "100%", marginTop: 20 }}>
                      <Grid item xs={12}>
                            <Button onClick={()=> enableCategories ? addToCartHandle(currentProduct): addToRedeemHandle(currentProduct)} style={{ width: "100%", borderRadius: 5, height: 30, opacity: .7 }} disableElevation variant="contained" size="small">
                            {enableCategories ? "Add to Cart" : "Select"}
                            </Button>
                            </Grid>
                            </Grid>: null}

                    {currentProduct.categories ? <Grid>
                      <Grid container style={{ marginTop : 20, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {"Categories"}
                        </Typography>
                      </Grid>

                      <Grid container spacing={1} style={{ marginTop : 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {currentProduct.categories.map((val, index) => {

                          const currentIndex = renderCategories().map(val => val.id).indexOf(val.id);
                          return(
                            <Grid key={"category-button-" + index} item>
                              {renderCategories()[currentIndex] && renderCategories()[currentIndex].link ? 
                              <Button style={{ opacity:.7 }} onClick={()=> { 
                                setOpenDialog(false)
                                selectCategory(renderCategories()[currentIndex].id) } }
                              disableElevation variant="outlined" size="small">
                              {renderCategories()[currentIndex].name}
                              </Button> : null}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>: null}
                 </Grid>
               </Grid>
            </Grid>
 
          </Grid>
        )

      }
       
    }

    const removeFromCartHandle = (product) => {
      props.removeItem(product, props.currentUser)
    }

    const removeRedeemFromCartHandle = (product) => {
      props.removeRedeemItem(product, props.currentUser)
    }

    const addToCartHandle = (product) => {
      props.addItem(product, props.currentUser)
    }

    const addToRedeemHandle = (product) => {
      if(product.type === "pod" && getTotalPODs() < maxPod){
        props.addRedeemItem(product, props.currentUser)
      }
      
      if(product.type === "device" && getTotalDevices() < maxDevice){
        props.addRedeemItem(product, props.currentUser)
      }
    }

    const showProductDetails = (val) => {
      setCurrentProduct(val)
      setMediaOption("products")
      setDialogLoading(false)
      setAgreeButton(false)
      setDialogTitle(val.name)
      setOptions("productDetails")
      setDialogDes("")
      setFullScreenDialog(false)
      setOpenDialog(true)
      setDialogMaxWidth("md")
    }

    const getTotalPODs = () => {
      let total = 0;

      renderRedeemItems().forEach(val=>{
        
        if(val.type === "pod" && val.redeem){
          total = total + parseInt(val.quantity)
        }
        
      })

      return total;
    }

    const getTotalDevices = () => {
      let total = 0;
      renderRedeemItems().forEach(val=>{
        
        if(val.type === "device" && val.redeem ){
          total = total + parseInt(val.quantity)
        }
        
      })

      return total;
    }

    const selectCategory = (val) => {
      setCurrentCategory(val)
    }

    if(loading){
      return (
      <Grid style={{ display: 'flex',  justifyContent:'center', alignItems:'center',padding: 70 }}>
        <CircularProgress 
          disableShrink
          color="primary" />
      </Grid>);
    }
    
    return (
     <Grid container >
        <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          maxWidth={dialogMaxWidth}
          dialogLoading={dialogLoading}
          component={renderComponent()}
          handleClose={handleClose}/>

        <Grid item xs={12} sm={12} md={3} lg={2}   style={{ position: 'sticky' }}>
          <Accordion defaultExpanded style={{ borderRadius: 0 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="caption" style={{ fontSize: 14 }}>Product Categories</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ padding: 0 }}>
              <Grid style={{ display: "flex", flexDirection: 'column', width: "100%", paddingBottom: 20 }}>
                <Typography variant="caption" style={{ fontSize: 14 }}>
                    <ListItem  onClick={()=> selectCategory(null)} button style={{ color: currentCategory === null ? "#99d700" : lightColor,  padding: 5, paddingLeft: 15  }}  >
                    {"All"}
                    </ListItem>
                  </Typography>
              {renderCategories().sort((a,b) => a.name.localeCompare(b.name)).map((val, index) => {

                  return(
                    <Typography key={"categories-list-"+index} variant="caption" style={{ fontSize: 14 }}>
                      <ListItem onClick={()=> selectCategory(val.id)} button  style={{ color: currentCategory === val.id ? "#99d700" : lightColor, padding: 5, paddingLeft: 15 }} >
                      {val.name}
                      </ListItem>
                    </Typography>
                  )

              })}
              </Grid>
            </AccordionDetails>
          </Accordion>
         
        </Grid>
        
        <Grid item xs={12} sm={12} md={9} lg={10} >
          {enableCategories ? null : <Grid item xs={12} sm={12} style={{ padding: 10 }}>
            <Button style={{ backgroundColor: "#1c1c1c", border: '1px solid #414141', margin: 10, color: "#99d700" }} disableElevation disableRipple variant="contained" size="small">
            { getTotalPODs() + " / "+ maxPod + " PODs"}
            </Button>
            <Button style={{ backgroundColor: "#1c1c1c", border: '1px solid #414141', margin: 10, color: "#99d700" }} disableElevation disableRipple variant="contained" size="small">
            { getTotalDevices() + " / "+ maxDevice + " Devices"}
            </Button>

            <Button style={{ backgroundColor: "#1c1c1c", border: '1px solid #414141', margin: 10, color: "white" }} disableElevation disableRipple variant="contained" size="small">
            {"Left " +  props.currentUser.user_redeem_time  + " Times" }
            </Button>

            <Button disabled={getTotalPODs() === maxPod && getTotalDevices() === maxDevice && props.currentUser.user_redeem_time > 0 ? false:true} component={Link} to={'/checkout_redeem'} color='primary' style={{  margin: 10 }} disableElevation variant="contained" size="small">
            { "Checkout"}
            </Button>
          </Grid>}
          {renderListProducts()}
        </Grid>
     </Grid>
    );

}

const mapDispatchToProps = dispatch => ({
  addItem: (item, user) => dispatch(addItem(item, user)),
  removeItem: (item, user) => dispatch(removeItem(item, user)),
  changeItemQuantity: (quantity, item, user) => dispatch(changeItemQuantity(quantity, item, user)),

  addRedeemItem: (item, user) => dispatch(addRedeemItem(item, user)),
  removeRedeemItem: (item, user) => dispatch(removeRedeemItem(item, user)),
  changeRedeemItemQuantity: (quantity, item, user) => dispatch(changeRedeemItemQuantity(quantity, item, user)),
});

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  redeemItems: selectRedeemItems,
  currentUser: selectCurrentUser,
  totalRedeem: selectRedeemTotal,
  productItems: selectProductItems,
  categoryItems: selectCategoryItems,
})

const routeNavigator = withRouter(Index);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(routeNavigator);