import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import Dialog from '../../../modules/components/Dialog';
import firebase from "firebase";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';

import MyOrders from './MyOrders';
import MyProfile from './MyProfile';
import GiftRedeem from './GiftRedeem';

//icons
import PersonIcon from '@material-ui/icons/Person';
import InboxIcon from '@material-ui/icons/Inbox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RedeemIcon from '@material-ui/icons/Redeem';

const useStyles = makeStyles((theme) => ({
  root: {
    display:"flex",
    flexGrow: 1,
    flexDirection: "column",
    alignItems: "center",
  }
}));

function Index (props){

  const classes = useStyles();

  //Dialog
  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogLoading, setDialogLoading] = React.useState(false);
  const [fullScreenDialog, setFullScreenDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [dialogDes, setDialogDes] = React.useState("");
  const [agreeButton, setAgreeButton] = React.useState(false);
  const [option, setOptions] = React.useState("");

  const handleClose = () => {
    setOpenDialog(false)
  };

  const handleAgree = () => {
    if(option === "logout"){
      firebase.auth().signOut();
    }

    setOpenDialog(false)
  }
  
  const handleSignout = () => {
    setDialogLoading(false)
    setAgreeButton(true)
    setDialogTitle("Logout")
    setOptions("logout")
    setDialogDes("You will be returned to the login screen")
    setFullScreenDialog(false)
    setOpenDialog(true)
  }

  const renderPage = () => {
    if(props.match.path === "/myorders"){
      return <MyOrders/>;
    }
    else if(props.match.path === "/myprofile"){
      return <MyProfile/>;
    }
    else if(props.match.path === "/giftredeem"){
      return <GiftRedeem/>;
    }
  }

  return (
    <Grid className={classes.root}>
      <Dialog 
          agreeTxt={"Yes"}
          disagreeTxt={"No"}
          description={dialogDes}
          title={dialogTitle}
          open={openDialog} 
          agreeButton={agreeButton}
          fullScreenDialog={fullScreenDialog}
          option={option}
          dialogLoading={dialogLoading}
          handleAgree={handleAgree}
          handleClose={handleClose}/>
      <Grid container>
        <Hidden mdDown>
          <Grid item xs={12} md={3} style={{ padding: 20 }}>
            <Grid style={{ display: "flex", flexDirection: "column", padding: 20, backgroundColor: "#363636", border: "1px solid #7e7e7e", borderRadius: 5 }}>

              <List component="div" disablePadding>
                <ListItem button component={Link} to="/myprofile" className={classes.drawerlist}>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"My Profile"}
                </Typography>} />
                </ListItem>
                <ListItem button component={Link} to="/myorders" className={classes.drawerlist}>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"My Orders"}
                </Typography>} />
                </ListItem>
                <ListItem button component={Link} to="/giftredeem" className={classes.drawerlist}>
                  <ListItemIcon>
                    <RedeemIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                  {"Gift Redeem"}
                </Typography>} />
                </ListItem>

                <Divider light />

                <ListItem button onClick={handleSignout}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography style={{ marginLeft: -20, fontSize: 14 }} variant="body2" component="p">
                      {"Logout"}
                    </Typography>} />
                </ListItem>

              </List>
              
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            {renderPage()}
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <Grid item xs={12}>
            {renderPage()}
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
    
  );
  
}

export default Index;