import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TopBanner from './Main/TopBanner';
import ProductsPage from './Main/ProductsPage';
import Products from './Main/Products';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    flexDirection: "column"
  }
})

class Index extends Component {

  render(){

    const { classes } = this.props;

    return (
      <Grid className={classes.root}>
        <TopBanner/>
        {this.props.match.params.id === "redeem_products" ? <ProductsPage {...this.props}/> : <Products {...this.props}/> }
      </Grid>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Index);